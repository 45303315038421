<template>
  <div>
    <div class="card card-custom gutter-b">
      <div class="card-header">
        <div class="card-title">
          <h3 class="card-label">Ajout d'un poste</h3>
        </div>
        <div class="card-toolbar">
          <a class="btn btn-icon btn-sm btn-success mr-1" @click="save">
            <i class="icon-md fas fa-save"></i>
          </a>
        </div>
      </div>
      <div class="card-body">
        <b-form>
          <b-form-group label="Titre:">
            <b-form-input v-model="post.titre"></b-form-input>
          </b-form-group>
          <b-form-group label="Commentaire:">
            <b-form-textarea id="textarea" v-model="post.text" placeholder="Enter something..." rows="3" max-rows="6"></b-form-textarea>
          </b-form-group>
          <b-row>
            <b-col md="6">
              <b-form-group label="Nom:">
                <b-form-input v-model="post.psedo_nom"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Email:">
                <b-form-input v-model="post.psedo_email"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Note:">
                <b-form-input v-model.number="post.note" type="number"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Url:">
                <b-form-input v-model="post.url"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Devis Id:">
                <b-form-input v-model.number="post.devis_id"></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Date de demenagement:">
                <b-form-datepicker v-model="post.date_dem"></b-form-datepicker>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <b-form-group label="Statut:">
                <select class="form-control" v-model="post.status">
                  <option value=1>actif</option>
                  <option value=0>inactif</option>
                </select>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Posteur:">
                <select class="form-control" v-model="post.user_id">
                  <option v-for="(u,i) in params.ReviewUser" :key="i" :value="u.id">{{u.nom}} {{u.prenom}}</option>
                </select>
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </div>
    </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  data(){
    return {
      post:{
        id: 0,
        titre:"",
        text:"",
        psedo_nom:"",
        psedo_email:"",
        note:0,
        url:"",
        ip:"",
        user_id:0,
        devis_id:"",
        date_dem:"",
        date_create:null,
        date_update:null,
        status:1
      },
      params: {
        ReviewUser:[]
      },
    };
  },
  components: {
  },
  watch: {
  },
  methods: {
    save(){
      this.$store.back.ajax('/magic/create?module=ReviewPost', this.post, (res) => {
        if(res.status === true){
          this.projet = res.data;
          this.$bvToast.toast(`Votre Post est bien sauvegarde !`, {
            title: 'Sauvegarder !',
            variant: 'success',
            solid: true
          });
          this.$router.push({path:`/review/post/detail/${res.data.id}`});
        }
      });
    }
  },
  computed: {
  },
  beforeMount(){
    let params = {
      modules: ['ReviewUser']
    }
    this.$store.back.ajax('/magic/all', params, (res) => {
      if(res.status === true){
        this.params.ReviewUser = res.data.ReviewUser
      }
    });
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Vue Bootstrap", route: "alert" },
      { title: "" }
    ]);
  }
};
</script>
